<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="showModal">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="$emit('close')">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
            <header class="bg-white border-b px-4 py-4 flex flex-wrap items-center">
              <svg class="mr-2 w-5 h-auto" width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.11328 24.3667H13.1758V11.45C13.1758 11.176 13.0748 10.9131 12.8951 10.7194C12.7154 10.5256 12.4716 10.4167 12.2174 10.4167H4.07161C3.81745 10.4167 3.57369 10.5256 3.39397 10.7194C3.21425 10.9131 3.11328 11.176 3.11328 11.45V24.3667ZM3.11328 24.3667H1.67578M16.0508 24.3667H21.8008M16.0508 24.3667H18.9258V19.7167C18.9258 19.3056 18.7743 18.9114 18.5047 18.6207C18.2352 18.33 17.8695 18.1667 17.4883 18.1667C17.107 18.1667 16.7414 18.33 16.4718 18.6207C16.2022 18.9114 16.0508 19.3056 16.0508 19.7167V24.3667ZM21.8008 24.3667V2.6667C21.8008 2.25561 21.6493 1.86137 21.3797 1.57068C21.1102 1.28 20.7445 1.1167 20.3633 1.1167H11.7383C11.357 1.1167 10.9914 1.28 10.7218 1.57068C10.4522 1.86137 10.3008 2.25561 10.3008 2.6667V7.3167H11.7383M21.8008 24.3667H23.2383M10.3008 4.2167H14.6133M3.11328 13.5167H5.26953M8.86328 24.3667H5.98828V21.2667C5.98828 20.8556 6.13973 20.4614 6.40932 20.1707C6.6789 19.88 7.04453 19.7167 7.42578 19.7167C7.80703 19.7167 8.17266 19.88 8.44225 20.1707C8.71183 20.4614 8.86328 20.8556 8.86328 21.2667V24.3667Z" stroke="#F42272" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <h2 class="my-1 text-lg font-semibold text-gray-800">{{ title }}</h2>
            </header>
            <main class="px-4 py-6">
              <h1 class="text-base font-semibold text-gray-800 whitespace-nowrap">Enter an address</h1>
              <div ref="geocoderRef" class="my-2"></div>
              <dynamic-form :form="form" @submitted="submit" @change="valueChanged" class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <template v-slot:name="{ control, onChange, onFocus, onBlur }">
                  <input
                    :id="control.name"
                    v-if="control"
                    class="form-control"
                    v-model="control.value"
                    type="text"
                    :name="control.name"
                    @change="onChange"
                    @focus="onFocus"
                    @blur="onBlur"
                  />
                  <p class="text-sm mt-2 text-gray-500">A descriptive name to easily identify the building (optional)</p>
                </template>
              </dynamic-form>
            </main>
            <toast
                v-if="errorMessage != null"
                class="fixed bottom-16 left-0 right-0 m-4 shadow z-50"
                :message="errorMessage"
                @close="errorMessage = null"
              />
            <footer class="bg-white bottom-0 border-t px-4 py-3 flex flex-wrap items-baseline justify-between">
              <button
              class="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm sm:text-sm font-medium rounded-md text-gray-800 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
              @click="$emit('close')">
              Cancel
              </button>
              <button v-if="isLoading" class="inline-flex items-center px-4 py-2 border border-transparent sm:text-sm font-medium rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                <svg class="-ml-1 mr-2 h-3 w-3 animate-spin" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle opacity="0.5" cx="10" cy="10" r="8" stroke="currentColor" stroke-width="3"/>
                  <path d="M10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2" stroke="currentColor" stroke-width="3" stroke-linecap="round"/>
                </svg>
                {{ isEditing ? 'Updating...' : 'Saving...' }}
              </button>
              <button
              v-else
              submit="true"
              :form="form.id"
              :disabled="submitButtonDisabled"
              class="disabled:opacity-50 ml-1 inline-flex items-center px-4 py-2 border border-transparent sm:text-sm font-medium rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
              {{ isEditing ? 'Update' : 'Save' }}
              </button>
            </footer>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { ref, reactive, computed, toRefs, onUpdated, nextTick } from 'vue';
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { TextField, SelectField, CustomField } from '@asigloo/vue-dynamic-forms';
import { createLabelList, missingFields } from '@/models/create-building';
import { COUNTRIES, US_STATES, CANADIAN_PROVINCES } from '@/models/create-building-constants';
import { DashboardBuilding } from '@/models/dashboard';
import Toast from '@/components/modals/Toast.vue';
import { createBuildingRequest, createUpdateBuildingRequest } from '@/models/create-building';

import buildingApiService from '@/network/services/building';

export default {
  name: 'CreateBuildingForm',
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    Toast,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    building: {
      type: DashboardBuilding,
      required: false
    }
  },
  computed: {
    title() {
      return this.isEditing ? `Edit ${this.formValues.name}` : 'New building';
    },
  },
  setup(props) {
    const formValues = reactive({
      country: 'Canada'
    });
    const formValuesAsRefs = toRefs(formValues);
    const submitButtonDisabled = computed(() => missingFields(formValues));
    const regions = computed(() => createLabelList(formValuesAsRefs.country.value === 'Canada' ? CANADIAN_PROVINCES : US_STATES));
    const isEditing = computed(() => props.building != null);
    const initialOrGeocodedValues = reactive({
      country: props.building?.building.country,
      street_address: props.building?.building.street_address,
      city: props.building?.building.city,
      region_code: props.building?.building.region_code,
      region: props.building?.building.region,
      latitude: props.building?.building.latitude,
      longitude: props.building?.building.longitude
    });
    let geocoderRef = ref(null); // references ref="geocoderRef" in template
    let errorMessage = ref(null);
    let isLoading = ref(false);

    // construct geocoder service
    mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_KEY;
    let mapboxGeocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      types: 'poi,address,postcode,place,region,country',
      countries: 'ca,us',
      render: render
    });
    mapboxGeocoder.on('result', (e) => updateFormValues(e.result));

    const form = computed(() => ({
      id: 'building-submission',
      fields: {
        name: CustomField({
          label: 'Building name',
          placeholder: 'A descriptive name to easily identify the building (optional)',
          customClass: 'sm:col-span-6 large-label',
          value: props.building?.building.name
        }),
        country: SelectField({
          label: 'Country',
          options: createLabelList(COUNTRIES),
          customClass: 'sm:col-span-3',
          value: initialOrGeocodedValues.country
        }),
        street_address: TextField({
          label: 'Address',
          customClass: 'sm:col-span-6',
          value: initialOrGeocodedValues.street_address
        }),
        city: TextField({
          label: 'City',
          customClass: 'sm:col-span-2',
          value: initialOrGeocodedValues.city
        }),
        region: SelectField({
          label: formValuesAsRefs.country.value === 'Canada' ? 'Province' : 'State',
          customClass: 'sm:col-span-2',
          options: regions,
          value: initialOrGeocodedValues.region
        }),
        region_code: TextField({
          label: formValuesAsRefs.country.value === 'Canada' ? 'Postal Code' : 'Zip code',
          customClass: 'sm:col-span-2',
          value: initialOrGeocodedValues.region_code
        }),
      },
      options: {
        resetAfterSubmit: false
      }
    }));

    onUpdated(() => {
      renderGeocoderIfNeeded();
      setInitialValues();
    });

    function valueChanged(values) {
      Object.assign(formValues, values);
    }

    function render(item) {
      return `<div class='p-2 hover:bg-gray-200 cursor-pointer'>
          <span class='text-gray-700 text-sm font-normal'>
            ${item.place_name}
          </span>
        </div>`;
    }

    async function updateFormValues(result) {
      initialOrGeocodedValues.country = convertCountryFrom(result);
      // Force updating the list of regions (based on the country selected)
      valueChanged(initialOrGeocodedValues);
      await nextTick();
      initialOrGeocodedValues.street_address = result.properties.address ?? `${result.address} ${result.text}`;
      initialOrGeocodedValues.city = result.context.find(element => element.id.includes('place'))?.text;
      initialOrGeocodedValues.region = convertRegionFrom(result);
      initialOrGeocodedValues.region_code = result.context.find(element => element.id.includes('postcode'))?.text;
      initialOrGeocodedValues.latitude = result.center[1];
      initialOrGeocodedValues.longitude = result.center[0];
    }

    function convertCountryFrom(result) {
      const country = result.context.find(element => element.id.includes('country'))?.text;
      if (country === 'United States') {
        return 'United States of America';
      }
      return country;
    }

    function convertRegionFrom(result) {
      const region = result.context.find(element => element.id.includes('region'))?.text;
      if (region === 'Quebec') {
        return 'Québec';
      }
      return region;
    }

    function renderGeocoderIfNeeded() {
      mapboxGeocoder.addTo(geocoderRef.value);
      mapboxGeocoder.clear();
    }

    function setInitialValues() {
      initialOrGeocodedValues.country = props.building?.building.country;
      initialOrGeocodedValues.street_address = props.building?.building.street_address;
      initialOrGeocodedValues.city = props.building?.building.city;
      initialOrGeocodedValues.region = props.building?.building.region;
      initialOrGeocodedValues.region_code = props.building?.building.region_code;
      initialOrGeocodedValues.latitude = props.building?.building.latitude;
      initialOrGeocodedValues.longitude = props.building?.building.longitude;
    }

    return {
      form,
      formValues,
      submitButtonDisabled,
      valueChanged,
      isLoading,
      isEditing,
      geocoderRef,
      errorMessage,
      mapboxGeocoder,
      initialOrGeocodedValues
    };
  },
  methods: {
    async submit(formData) {
      try {
        this.isEditing ? await this.update(formData) : await this.create(formData);
        this.$emit('close');
      } catch (error) {
        this.errorMessage = `There was a problem ${this.isEditing ? 'updating': 'creating'} the building`;
      }
    },
    async update(formData) {
      formData = {
        ...formData,
        latitude: this.initialOrGeocodedValues.latitude ?? this.building.building?.latitude,
        longitude: this.initialOrGeocodedValues.longitude ?? this.building.building?.longitude
      };
      try {
        this.isLoading = true;
        await buildingApiService.updateBuilding(createUpdateBuildingRequest(formData, this.building.id));
      } finally {
        this.isLoading = false;
      }
    },
    async create(formData) {
      try {
        this.isLoading = true;
        await buildingApiService.createBuilding(createBuildingRequest({
          ...formData,
          latitude: this.initialOrGeocodedValues.latitude,
          longitude: this.initialOrGeocodedValues.longitude
        }));
      } finally {
        this.isLoading = false;
      }
    },
  }
};
</script>

<style>
.mapboxgl-ctrl-geocoder--icon-search {
  display: none;
}

.mapboxgl-ctrl-geocoder--pin-right {
  display: none;
}

.suggestions-wrapper {
  margin-top: 0.5rem;
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: 0.375rem;
  width: 100%;
}
</style>
